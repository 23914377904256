import { debounce } from '~/utils/debounce.util';

export const useAppBarSearch = () => {
  const { $router }: any = useNuxtApp();
  const route = useRoute();
  const searchQuery: any = ref('');
  onMounted(() => {
    searchQuery.value = route?.query?.q || '';
  });
  const onSearchChange = debounce((event: any) => {
    const { q, ...restQueryParam } = route.query;
    if (event.target.value) {
      $router.push({ query: { ...restQueryParam, q: event.target.value, page: 1 } });
    }
    else {
      $router.push({ query: { ...restQueryParam, page: 1 } });
    }
  }, 500);

  const onSearchClear = () => {
    const { q, page, ...restQueryParam } = route.query;
    $router.push({ query: { ...restQueryParam } });
  };

  return { searchQuery, onSearchChange, onSearchClear };
};
