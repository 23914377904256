<script setup lang="ts">
import { LeftArrowIcon, ShareIcon } from '~/utils/svg.util';
import ArrivalStatusBadge from '~/components/common/ArrivalStatusBadge.vue';
import LanguageDropdownMenu from '~/components/common/LanguageDropdownMenu.vue';
import ClipBoard from '~/components/ClipBoard.vue';
import { useSingleContainerStore } from '~/store/single_container';
import { normalise } from '~/utils/common.utils';
import type { ISingleContainer } from '~/types/single-container';

const containerStore = useSingleContainerStore();
const { t } = useI18n();

const router = useRouter();
const route = useRoute();

const showCopyURLConfirmationToolTip: Ref<boolean> = ref(false);
const container: ISingleContainer = computed(() => containerStore.container);

const goBack = () => {
  router.push('/tracking/container');
};
const isEmbed = computed(() => route.meta.name === 'singleContainerTrackingDetailsEmbed');
const getTsStops = (container: ISingleContainer) => {
  const tsLength = container.transhipmentPortNames.length;
  return tsLength === 0
    ? 'direct'
    : tsLength.length > 3
      ? 'via 4 t/s ports'
      : `via ${container.transhipmentPortNames.join(', ').toLowerCase()}`;
};

const onShareClick = () => {
  showCopyURLConfirmationToolTip.value = true;
  try {
    navigator.clipboard.writeText(window.location.href);
    setTimeout(() => {
      showCopyURLConfirmationToolTip.value = false;
    }, 2000);
  }
  catch (err) {
    console.error('Failed to copy: ', err);
  }
};
</script>

<template>
  <v-row
    v-if="container"
    class="container-page-header"
    no-gutters
  >
    <v-col cols="6">
      <div class="d-flex align-center">
        <LeftArrowIcon
          v-if="!isEmbed"
          style="font-size: 22px"
          class="cursor-pointer"
          data-testid="back-to-container-page"
          @click="goBack"
        />

        <div class="ml-5">
          <div class="d-inline-block">
            <h3
              class="d-flex container-no copy-block"
              data-testid="container-number"
            >
              <span class="heading-desktop-22 d-flex mr-3">
                <span>
                  {{ $t('containerPage.containerNo') }}: {{ container.containerNumber }}
                </span>
              </span>

              <ClipBoard :copy-text="container?.containerNumber">
                <ArrivalStatusBadge :container="container" />
              </ClipBoard>
            </h3>
          </div>
          <p
            v-if="container.pol && container.pod"
            class="port-names text-desktop-body-small mb-0 text-capitalize"
          >
            {{ `${normalise(container.polName)}` }} <span class="text-lowercase">to</span>
            {{ `${normalise(container.podName)}` }} ({{ getTsStops(container) }})
          </p>
        </div>
      </div>
    </v-col>

    <v-col
      class="align-center justify-end d-flex"
      cols="6"
    >
      <div class="d-flex justify-md-end flex-column  flex-sm-row action-buttons-section">
        <v-tooltip
          v-model="showCopyURLConfirmationToolTip"
          top
        >
          <template #activator="{ props }">
            <v-btn
              class="heading-desktop-5 text-black-2 border-black-2 ml-sm-4 header-btn"
              variant="outlined"
              v-bind="props"
              @click="onShareClick"
            >
              <template #prepend>
                <ShareIcon class="mr-2 heading-desktop-11" />
              </template>
              {{ t('v2.singleContainerPage.share') }}
            </v-btn>
          </template>
          <span>{{ $t('v2.singleContainerPage.shareURLCopyMessage') }}</span>
        </v-tooltip>
        <LanguageDropdownMenu
          v-if="!isEmbed"
          class-name="ml-sm-4"
        />
      </div>
    </v-col>
  </v-row>
</template>

<style lang="scss"></style>

<style lang="scss">
.container-page-header {
  padding: 16px 19px !important;
  border-bottom: 1px solid;
  max-height: 80px;
  @extend .border-gray-4;
  @extend .bg-gray-6;

  .container-no {
    position: relative;
    align-items: center;

    .status {
      position: relative;
      left: 0;
    }

  }

  .copy-block {
    .copy-clipboard {
      display: none;
    }

    &:hover {
      .copy-clipboard {
        display: block;
      }
    }
  }

  .port-names {
    margin-top: 1px !important;
  }

  .back-button {
    margin: -2px 0 0 -6px;
    zoom: 0.8;
  }

  .action-buttons-section {
    .header-btn.v-btn.v-size--default {
      height: 34px !important;
      min-height: 34px !important;
    }

    .header-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: capitalize;
    }

    .v-btn {
      border: 1px solid !important;
      padding: 8px 12px !important;
      border-radius: 8px !important;
      height: 34px !important;
      min-height: 34px !important;

      .button-v2-label,
      .v-btn__content {
        @extend .heading-mobile-5;
      }
    }

    @media (max-width: 600px) {
      flex-direction: row !important;
      justify-content: space-between !important;

      .new-ui-button {
        display: none !important;
      }

      .v-btn {
        width: 33% !important;
        text-indent: -9999px;
      }
    }
  }
}
</style>
