<script setup>
const resourceStore = useResourceStore();
const vesselStore = useVesselsStore();
const snackbar = useSnackbar();

const vesselOptions = computed(() => resourceStore.vessels.map(vessel => ({
  id: vessel.id,
  title: vessel.label,
})));
const portOptions = computed(() => resourceStore.ports.map(port => ({
  id: port.id,
  title: port.label,
})));
const loading = ref(false);
const isActive = ref(false);
const form = ref({
  vessel: '',
  trackPerpetually: false,
  targetPort: '',
  targetPortETA: undefined,
});

const uploadVessel = async () => {
  const payload = preparePayload(form.value);
  loading.value = true;
  try {
    const response = await vesselStore.uploadVessel(payload);
    if (response.id) {
      isActive.value = false;
      snackbar.add({ text: 'Vessels Added Successfully', type: 'success' });
    }
  }
  catch (error) {
    console.error('Error:', error);
  }
  finally {
    loading.value = false;
  }
};
const preparePayload = (form) => {
  const payload = {
    imo: form.vessel,
  };

  if (!form.trackPerpetually) {
    payload.target_port_code = form.targetPort;
    payload.target_port_eta = form.targetPortETA;
  }

  return payload;
};

// usage
const fetchVessels = async (inputEvent) => {
  await resourceStore.fetchVessels(inputEvent.target.value);
};
const fetchPorts = async (inputEvent) => {
  await resourceStore.fetchPorts(inputEvent.target.value);
};

// reset the form upon opening the dialog
watch(isActive, async (value) => {
  if (value) {
    await resourceStore.fetchVessels();
    await resourceStore.fetchPorts();
    form.value = {
      vessel: '',
      trackPerpetually: false,
      targetPort: '',
      targetPortETA: undefined,
    };
  }
});
const isFormValid = computed(() => {
  return form.value.vessel && (!form.value.trackPerpetually ? form.value.targetPort : true);
});
</script>

<template>
  <v-dialog
    v-model="isActive"
    width="700"
    persistent
  >
    <template #activator="{ props: activatorProps }">
      <v-btn
        v-bind="activatorProps"
        prepend-icon="mdi-plus"
        color="primary"
        variant="outlined"
        class="mr-4"
        @click="isActive = true"
      >
        Add Vessels
      </v-btn>
    </template>

    <template #default="{ }">
      <v-card title="Add Vessels">
        <v-card-text>
          <div>
            <div class="mb-3">
              <label class="block text-sm font-medium text-gray-700 mb-1">Select Vessel</label>
              <v-autocomplete
                v-model="form.vessel"
                density="compact"
                clear-icon="mdi-close"
                hide-details
                clearable
                :items="vesselOptions"
                item-text="label"
                item-value="id"
                variant="outlined"
                @input="fetchVessels"
              />
            </div>
            <div class="mb-3 flex items-center">
              <v-checkbox
                v-model="form.trackPerpetually"
                density="compact"
                hide-details
                label="Track perpetually?"
              />
            </div>
            <div
              v-if="!form.trackPerpetually"
              class="mb-3"
            >
              <label
                for="port"
                class="block text-sm font-medium text-gray-700 mb-1"
              >Select Target Port</label>
              <v-autocomplete
                v-model="form.targetPort"
                density="compact"
                clear-icon="mdi-close"
                hide-details
                clearable
                :items="portOptions"
                item-text="label"
                item-value="id"
                variant="outlined"
                @input="fetchPorts"
              />
            </div>
            <div
              v-if="!form.trackPerpetually"
              class="mb-3"
            >
              <label
                for="eta"
                class="block text-sm font-medium text-gray-700 mb-1"
              >Target Port ETA</label>
              <v-date-input
                v-model="form.targetPortETA"
                density="compact"
                hide-details
                prepend-icon=""
                prepend-inner-icon="$calendar"
                variant="outlined"
                :min="new Date()"
                readonly
              />
            </div>
          </div>
        </v-card-text>

        <v-card-actions class="pb-4 mr-3">
          <v-btn
            text="Close"
            color="primary"
            variant="outlined"
            @click="isActive = false"
          />
          <v-btn
            :disabled="!isFormValid"
            :loading="loading"
            text="Upload"
            color="primary"
            variant="flat"
            @click="uploadVessel"
          />
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
