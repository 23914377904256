<script setup lang="ts">
import OrgSwitcher from '~/components/OrgSwitcher.vue';
import { usePageTitle } from '~/composables/usePageTitle';
import { useAppBarSearch } from '~/composables/useAppBarSearch';
import { LeftArrowIcon } from '~/utils/svg.util';
import LanguageDropdownMenu from '~/components/common/LanguageDropdownMenu.vue';

const props = defineProps({
  disableTitle: {
    type: Boolean,
    default: false,
  },
  disableOrgSelector: {
    type: Boolean,
    default: false,
  },
  enableLanguageSelector: {
    type: Boolean,
    default: false,
  },
  disableSearchInput: {
    type: Boolean,
    default: false,
  },
  backRoute: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  leftCols: {
    type: String,
    default: '4',
  },
  centerCols: {
    type: String,
    default: '3',
  },
  rightCols: {
    type: String,
    default: '5',
  },
});
const router = useRouter();
const { pageTitle } = usePageTitle();
const { searchQuery, onSearchChange, onSearchClear } = useAppBarSearch();
const goBack = () => {
  router.push(props.backRoute);
};
</script>

<template>
  <v-container
    fluid
    class="py-5 px-8"
  >
    <v-row no-gutters>
      <v-col
        :cols="leftCols"
        class="d-flex align-center"
      >
        <slot name="left" />
        <LeftArrowIcon
          v-if="backRoute"
          class="cursor-pointer ma-0 mr-2 appbar-back-icon"
          @click="goBack"
        />
        <h2
          v-if="!disableTitle || title"
          class="heading-desktop-4 text-black-1"
        >
          {{ title ? title : pageTitle }}
        </h2>
        <slot name="afterTitle" />
      </v-col>
      <v-col
        :cols="centerCols"
        class="d-flex align-center"
      >
        <slot name="center" />
        <v-responsive
          v-if="!disableSearchInput"
          class="mx-auto"
        >
          <v-text-field
            v-model="searchQuery"
            width="360"
            prepend-inner-icon="mdi-magnify"
            variant="outlined"
            hide-details
            label="Search"
            clearable
            single-line
            density="comfortable"
            clear-icon="mdi-close"
            @keyup="onSearchChange"
            @click:clear="onSearchClear"
          />
        </v-responsive>
      </v-col>
      <v-col
        :cols="rightCols"
        class="d-flex justify-end align-center"
      >
        <slot name="right" />
        <LanguageDropdownMenu v-if="enableLanguageSelector" />
        <OrgSwitcher v-if="!disableOrgSelector" />
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped lang="scss">
.appbar-back-icon {
  position: relative;
  left: -12px;
}
</style>
