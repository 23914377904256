<script setup lang="ts">
import OrgSwitcher from '~/components/OrgSwitcher.vue';
import LanguageDropdownMenu from '~/components/common/LanguageDropdownMenu.vue';

const userStore = useUserStore();
</script>

<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="4"
        class="d-flex align-center"
      >
        <div>
          <h2 class="heading-desktop-4 text-black-1">
            {{ userStore.user.given_name }} {{ userStore.user.family_name }}
          </h2>

          <p>{{ userStore.user.email }} </p>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="4"
      />
      <v-col
        cols="12"
        sm="4"
        class="d-flex justify-end align-center"
      >
        <div class="d-flex">
          <!--          <v-btn -->
          <!--            class="text-none text-subtitle-1 mr-2" -->
          <!--            color="secondary" -->
          <!--            variant="outlined" -->
          <!--            size="large" -->
          <!--            append-icon="mdi-translate" -->
          <!--          > -->
          <!--            Language -->
          <!--          </v-btn> -->
          <LanguageDropdownMenu class-name="large-btn mr-2" />
          <OrgSwitcher />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
