<script setup lang="ts">
import * as _ from 'lodash';
import AppBarWrapper from '~/components/layout/AppBars/AppBarWrapper.vue';
import { useSingleAirVisibility } from '~/store/single_air_visibility';
import { LeftArrowIcon, FlightIcon, ShareIcon } from '~/utils/svg.util';
import AppbarSkeletonLoader from '~/components/common/AppbarSkeletonLoader.vue';
import { getAwbInfoStatusColor } from '~/utils/single_air_visibility.utils';
import StatusChip from '~/components/common/StatusChip.vue';

const { $router } = useNuxtApp();
const singleAirVisibilityStore: any = useSingleAirVisibility();

const goBack = () => {
  $router.push('/tracking/air_visibility');
};
const route = useRoute();
const isEmbed = computed(() => route.meta.name === 'singleAirVisibilityEmbed');

const airwayBillNumber = computed(() => _.get(singleAirVisibilityStore.bookmark, 'metadata.airway_bill'));
const origin: any = computed(() => _.get(singleAirVisibilityStore.bookmark, 'awb_info.from', {}));
const destination: any = computed(() => _.get(singleAirVisibilityStore.bookmark, 'awb_info.to', {}));
const onShareClick = () => {
  navigator.clipboard.writeText(location.href);
};

const statusComputed = computed(() => singleAirVisibilityStore.bookmark?.awb_info?.status);
const colorComputed = computed(() => getAwbInfoStatusColor(singleAirVisibilityStore.bookmark));
</script>

<template>
  <ClientOnly>
    <AppbarSkeletonLoader v-if="singleAirVisibilityStore.isLoading" />
    <AppBarWrapper
      v-else
      :disable-title="true"
      disable-search-input
      disable-org-selector
      left-cols="7"
      center-cols="0"
      right-cols="5"
    >
      <template #left>
        <div class="d-flex align-center">
          <LeftArrowIcon
            v-if="!isEmbed"
            class="cursor-pointer ma-0 mr-5 appbar-back-icon"
            @click="goBack"
          />
          <div
            v-if="airwayBillNumber"
            class="mr-5"
          >
            <p class="heading-desktop-22 text-black-2 mb-0">
              MAWB No: {{ airwayBillNumber }}
            </p>
            <p
              v-if="origin.country && destination.country"
              class="heading-desktop-10 text-black-3 mb-0"
            >
              {{ origin.country }} to {{ destination.country }}
            </p>
          </div>
          <FlightIcon />
        </div>
      </template>
      <template #afterTitle>
        <div class="ml-4 air-vis-single-app-bar-header">
          <StatusChip
            v-if="statusComputed"
            :status="statusComputed"
            :color="colorComputed"
            size="small"
          />
        </div>
      </template>

      <template
        v-if="!isEmbed"
        #right
      >
        <v-tooltip
          top
        >
          <template #activator="{ props }">
            <v-btn
              class="heading-desktop-5 text-black-2 border-black-2 ml-sm-4 header-btn"
              variant="outlined"
              v-bind="props"
              @click="onShareClick"
            >
              <template #prepend>
                <ShareIcon class="mr-2 heading-desktop-11" />
              </template>
              {{ $t('v2.singleContainerPage.share') }}
            </v-btn>
          </template>
          <span>{{ $t('v2.singleContainerPage.shareURLCopyMessage') }}</span>
        </v-tooltip>
      </template>
    </AppBarWrapper>
  </ClientOnly>
</template>

<style lang="scss">
.air-vis-single-app-bar-header {
  .v-chip {
    height: 24px;

    .v-chip__content {
      text-transform: capitalize;
    }

    @extend .bg-primary-7;
    @extend .text-gray-6;
  }
}
</style>
