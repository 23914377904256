import { useI18n } from 'vue-i18n';

export const usePageTitle = () => {
  const { t } = useI18n();
  const route: any = useRoute();
  const pageTitle = ref('');

  const setPageTitle = () => {
    if (route.meta.title) {
      pageTitle.value = t(route.meta.title);
      return;
    }
    if (route.meta.name === 'analytics') {
      pageTitle.value = t(`appBarTitle.${route.params.id}`);
    }
    else if (route.meta.name === 'lookup') {
      pageTitle.value = t(`appBarTitle.lookup_${route.params.type}`);
    }
    else if (route.meta.name) {
      pageTitle.value = t(`appBarTitle.${route.meta.name}`);
    }
    else {
      pageTitle.value = route.path;
    }
  };

  // Set the page title initially
  setPageTitle();

  watch(() => route.fullPath, setPageTitle);

  return { pageTitle };
};
