<script setup lang="ts">
import OrgSwitcher from '~/components/OrgSwitcher.vue';
import { useAppBarSearch } from '~/composables/useAppBarSearch';
import { usePageTitle } from '~/composables/usePageTitle';
import AddMAWB from '~/components/air_visibility/air_visibility_list/AddMAWB.vue';

const { searchQuery, onSearchChange, onSearchClear } = useAppBarSearch();
const { pageTitle } = usePageTitle();
</script>

<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="4"
        class="d-flex align-center"
      >
        <div>
          <h2 class="heading-desktop-4 text-black-1">
            {{ pageTitle }}
          </h2>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        class="d-flex align-center"
      >
        <div>
          <v-responsive
            class="mx-auto"
            width="344"
          >
            <v-text-field
              v-model="searchQuery"
              prepend-inner-icon="mdi-magnify"
              variant="outlined"
              hide-details
              label="Search"
              clearable
              single-line
              density="comfortable"
              clear-icon="mdi-close"
              @keyup="onSearchChange"
              @click:clear="onSearchClear"
            />
          </v-responsive>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        class="d-flex justify-end"
      >
        <div class="d-flex">
          <AddMAWB />
          <OrgSwitcher />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
