<script setup lang="ts">
// import { usePageTitle } from '~/composables/usePageTitle';
import { LeftArrowIcon } from '~/utils/svg.util';

// const { pageTitle } = usePageTitle();
const { $router } = useNuxtApp();
const reportsStore: any = useReportsStore();

const activeBuilder: any = ref(reportsStore.activeBuilder);

const reportName = ref('');

const searchQuery = ref(reportsStore.querySearch);
const onChandler = () => {
  reportsStore.setQueryValue(searchQuery.value);
};
watch(
  () => reportsStore.activeBuilder,
  (newValue: any) => {
    reportName.value = newValue;
    activeBuilder.value = newValue;
  },
);

const goBack = () => {
  $router.push('/reports');
};
</script>

<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="4"
        class="d-flex align-center"
      >
        <div class="d-flex align-center">
          <LeftArrowIcon
            class="cursor-pointer ma-0 mr-5 appbar-back-icon"
            @click="goBack"
          />
          <div
            class="mr-5"
          >
            <p class="heading-desktop-22 text-black-2 mb-0">
              Report: {{ reportName.name || '' }}
            </p>
          </div>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        class="d-flex align-center"
      >
        <div>
          <v-responsive
            class="mx-auto"
            width="344"
          >
            <v-text-field
              v-model="searchQuery"
              prepend-inner-icon="mdi-magnify"
              variant="outlined"
              hide-details
              label="Search for BOL, Container No., SCAC"
              clearable
              single-line
              density="compact"
              clear-icon="mdi-close"
              @keyup="onChandler"
            />
          </v-responsive>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        class="d-flex justify-end"
        style="gap: 20px;"
      >
        <div
          v-if="Object.keys(activeBuilder).length"
          class="d-flex align-center"
          style="gap: 10px;"
        >
          <v-tooltip
            location="bottom"
          >
            <template #activator="{ props }">
              <v-btn
                :icon="!reportsStore.isReportEdit? 'mdi mdi-pencil' : 'mdi mdi-eye-circle'"
                v-bind="props"
                variant="flat"
                size="small"
                @click="reportsStore.setIsReportEdit()"
              />
            </template>
            <span>{{ $t("Report Edit") }}</span>
          </v-tooltip>
          <v-tooltip
            location="bottom"
          >
            <template #activator="{ props }">
              <div v-bind="props">
                <ReportsDashboardColReportEmail :item="activeBuilder" />
              </div>
            </template>
            <span>{{ $t("Schedule Mail") }}</span>
          </v-tooltip>
          <v-tooltip
            location="bottom"
          >
            <template #activator="{ props }">
              <div v-bind="props">
                <ReportsDashboardColDownloadReport :item="activeBuilder" />
              </div>
            </template>
            <span>{{ $t("Download") }}</span>
          </v-tooltip>

          <v-tooltip
            location="bottom"
          >
            <template #activator="{ props }">
              <div v-bind="props">
                <ReportsDashboardColReportSetting :item="activeBuilder" />
              </div>
            </template>
            <span>{{ $t("Settings") }}</span>
          </v-tooltip>
        </div>
        <div class="d-flex">
          <OrgSwitcher />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
