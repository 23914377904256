<script setup lang="ts">
import OrgSwitcher from '~/components/OrgSwitcher.vue';
import { usePageTitle } from '~/composables/usePageTitle';

const { pageTitle } = usePageTitle();
</script>

<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="4"
        class="d-flex align-center"
      >
        <h2 class="heading-desktop-4 text-black-1">
          {{ pageTitle }}
        </h2>
      </v-col>
      <v-col
        cols="12"
        sm="4"
      />
      <v-col
        cols="12"
        sm="4"
        class="d-flex align-center justify-end"
      >
        <div
          class="d-flex align-center"
          style="gap: 20px;"
        >
          <div>
            <v-tooltip
              location="bottom"
            >
              <template #activator="{ props }">
                <div v-bind="props">
                  <ReportsCreateReport />
                </div>
              </template>
              <span>{{ $t("New Report") }}</span>
            </v-tooltip>
          </div>
          <OrgSwitcher />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
