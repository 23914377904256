<script setup lang="ts">
import DefaultAppBar from '~/components/layout/AppBars/DefaultAppBar.vue';
import ProfilePageAppBar from '~/components/layout/AppBars/ProfilePageAppBar.vue';
import ContainersPageAppBar from '~/components/layout/AppBars/ContainersPageAppBar.vue';
import SingleContainerAppBar from '~/components/layout/AppBars/SingleContainerAppBar.vue';
import VesselsPageAppBar from '~/components/layout/AppBars/VesselsPageAppBar.vue';
import FreightAuditListPageAppBar from '~/components/layout/AppBars/FreightAuditListPageAppBar.vue';
import FreightAuditSinglePageAppBar from '~/components/layout/AppBars/FreightAuditSinglePageAppBar.vue';
import ReportEditPageAppBar from '~/components/layout/AppBars/ReportEditPageAppBar.vue';
import ReportsPageAppBar from '~/components/layout/AppBars/ReportsPageAppBar.vue';
import AirVisibilitySinglePageAppBar from '~/components/layout/AppBars/AirVisibilitySinglePageAppBar.vue';
import BookingPageAppBar from '~/components/layout/AppBars/BookingPageAppBar.vue';
import SingleBookingPageAppBar from '~/components/layout/AppBars/SingleBookingPageAppBar.vue';
import AirVisibilityPageAppBar from '~/components/layout/AppBars/AirVisibilityPageAppBar.vue';

const route = useRoute();
</script>

<template>
  <ClientOnly>
    <v-app-bar
      v-if="!route.meta.noAppbar"
      flat
      height="80"
    >
      <ContainersPageAppBar v-if="route.meta.name==='containerTracking'" />
      <VesselsPageAppBar v-else-if="route.meta.name==='vesselTracking'" />
      <ProfilePageAppBar v-else-if="route.meta.name==='profilePage'" />
      <ReportEditPageAppBar v-else-if="route.meta.name==='singleReport'" />
      <ReportsPageAppBar v-else-if="route.meta.name==='reports'" />
      <BookingPageAppBar v-else-if="route.meta.name==='bookingContainers'" />
      <SingleBookingPageAppBar v-else-if="route.meta.name==='singleBooking'" />
      <SingleContainerAppBar
        v-else-if="route.meta.name==='singleContainerTracking'
          || route.meta.name==='singleContainerTrackingDetailsEmbed'
          ||route.meta.name==='singleContainerTrackingDetailsEmbedV2'"
      />
      <FreightAuditListPageAppBar v-else-if="route.meta.name==='freightAuditList'" />
      <FreightAuditSinglePageAppBar v-else-if="route.meta.name==='freightAuditSingleInvoice'" />
      <AirVisibilityPageAppBar v-else-if="route.meta.name==='airVisibility'" />
      <AirVisibilitySinglePageAppBar
        v-else-if="route.meta.name==='singleAirVisibility'
          ||route.meta.name==='singleAirVisibilityEmbed'"
      />
      <DefaultAppBar v-else />
    </v-app-bar>
  </ClientOnly>
</template>
