<script setup>
import { useAirVisibility } from '~/store/air_visibility';
import { useUtilsStore } from '~/store/utils';

const utilsStore = useUtilsStore();
const snackbar = useSnackbar();
const airVisibilityStore = useAirVisibility();
const loading = ref(false);
const isActive = ref(false);
const mawbInput = ref('');

const validateMAWB = (mawb) => {
  const mawbPattern = /^[0-9]{3}-[0-9]{8}$/;
  return mawbPattern.test(mawb);
};

const isValidateMAWB = computed(() => validateMAWB(mawbInput.value.trim()));

const uploadItems = async () => {
  if (!mawbInput.value || !isValidateMAWB.value) {
    return snackbar.add({ text: 'Please enter a valid MAWB', type: 'error' });
  }
  const payload = { airway_bill: mawbInput.value.trim() };
  try {
    utilsStore.setGlobalLoader(true);
    const res = await airVisibilityStore.addMAWBBookmarks(payload);
    if (res) {
      snackbar.add({ text: 'Added Successfully', type: 'success' });
      isActive.value = false;
      mawbInput.value = '';
    }
    else {
      snackbar.add({ text: 'Error while adding MAWB, Try again later', type: 'error' });
    }
  }
  catch (error) {
    snackbar.add({ text: 'Error while adding MAWB, Try again later', type: 'error' });
    console.error('Error:', error);
  }
  finally {
    utilsStore.setGlobalLoader(false);
  }
};
</script>

<template>
  <v-dialog
    v-model="isActive"
    width="700"
    persistent
  >
    <template #activator="{ props: activatorProps }">
      <v-btn
        v-bind="activatorProps"
        prepend-icon="mdi-plus"
        color="primary"
        variant="outlined"
        class="mr-4"
        @click="isActive = true"
      >
        Add MAWB
      </v-btn>
    </template>

    <template #default="{ }">
      <v-card title="Add Single MAWB">
        <v-card-text>
          <div>
            <div class="mb-3">
              <label class="block text-sm font-medium text-gray-700 mb-1">
                Input your MAWB in the format of XXX-YYYYYYYY
              </label>
              <v-textarea
                v-model="mawbInput"
                rows="4"
                no-resize
                variant="outlined"
              />
            </div>
          </div>
        </v-card-text>

        <v-card-actions class="pb-4 mr-3">
          <v-btn
            text="Close"
            color="primary"
            variant="outlined"
            @click="isActive = false"
          />
          <v-btn
            :disabled1="!isValidateMAWB"
            :loading="loading"
            text="Upload"
            color="primary"
            variant="flat"
            @click="uploadItems"
          />
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
