const DND_API: string = 'https://freightforce.portcast.io/';
const DND_API_ACCESS_TOKEN: string = 'bcb9168d-732b-436a-abb2-46f3d1af065d';

export const useAddInvoiceStore = defineStore('addInvoiceStore', {
  state: () => ({
  }),

  actions: {
    uploadInvoiceFile(formData: any) {
      try {
        const { $ofetch }: any = useNuxtApp();
        const data: any = $ofetch(`${DND_API}upload_pdf/`, {
          method: 'POST',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
            'access-token': DND_API_ACCESS_TOKEN,
          },
        });
        return data;
      }
      catch (e: any) {
        if (e.response) {
          if (e.response.status === 409) {
            throw new Error('Duplicate Found: Invoice already exists');
          }
        }
        throw new Error('Internal Server Error');
      }
    },
  },
});
