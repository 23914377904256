<script lang="ts" setup>
import { useAddInvoiceStore } from '~/store/add_invoice';
import { FileUploadIcon } from '~/utils/svg.util';
import { useUtilsStore } from '~/store/utils';

const addInvoiceStore = useAddInvoiceStore();
const utilsStore = useUtilsStore();

const snackbar = useSnackbar();

const showInvoiceUploadModal: any = ref(false);
const showInvoiceTypeModal: any = ref(false);
const uploader: any = ref(null);
const invoiceFile: any = ref(null);
const invoiceType: any = ref('');

const setShowInvoiceUploadModal = (value: boolean) => {
  showInvoiceUploadModal.value = value;
};

const setShowInvoiceTypeModal = (value: boolean) => {
  showInvoiceTypeModal.value = value;
};

const closeSideModal = () => {
  showInvoiceUploadModal.value = false;
};
const closeInvoiceTypeModel = () => {
  showInvoiceTypeModal.value = false;
};

const onUpload = (event: any) => {
  if (event?.target?.files?.length > 0) {
    // addInvoiceStore.setInvoiceFile(event?.target?.files[0]);
    invoiceFile.value = event?.target?.files[0];
  }

  setShowInvoiceUploadModal(false);
  setShowInvoiceTypeModal(true);
};

const onClickDropArea = () => {
  uploader.value.click();
};

const onDrop = (event: any) => {
  event.preventDefault();
  if (event?.dataTransfer?.files?.length) {
    // addInvoiceStore.setInvoiceFile(event?.dataTransfer?.files[0]);
    invoiceFile.value = event?.dataTransfer?.files[0];
  }
  setShowInvoiceUploadModal(false);
  setShowInvoiceTypeModal(true);
};
const closeSideModel = () => {
  setShowInvoiceUploadModal(false);
  setShowInvoiceTypeModal(false);
};
const uploadInvoiceFileStart = async () => {
  try {
    utilsStore.setGlobalLoader(false);
    const formData = new FormData();
    formData.append('file', invoiceFile.value);
    formData.append('invoice_type', invoiceType.value);
    const { bookmark_id } = await addInvoiceStore.uploadInvoiceFile(formData);
    window.open(`/v2/freight_audit/${bookmark_id}`, '_self');
    closeSideModel();
  }
  catch (e) {
    if (e instanceof Error) {
      snackbar.add({
        text: e.message,
        type: 'error',
      });
    }
    else {
      snackbar.add({
        text: 'Unknown Error',
        type: 'error',
      });
    }
  }
  finally {
    utilsStore.setGlobalLoader(false);
  }
};

const handleInvoiceTypeSelected = (type: string) => {
  // addInvoiceStore.setInvoiceType(type);
  invoiceType.value = type;
  uploadInvoiceFileStart();
};
</script>

<template>
  <div class="add-invoice">
    <v-btn
      variant="outlined"
      class="bg-gray-6 mr-3 text-black-2 text-desktop-body-large"
      @click="setShowInvoiceUploadModal(true)"
    >
      {{ $t('freightAudit.addInvoice') }}
    </v-btn>

    <LayoutSideModal
      v-model="showInvoiceUploadModal"
      :width="540"
      title="Add Invoices"
      :on-close="closeSideModal"
    >
      <div
        data-testid="add-container-bulk-upload-flow"
        style="height: 100%; padding: 10px 0;"
      >
        <input
          ref="uploader"
          class="d-none"
          type="file"
          accept=".pdf"
          data-testid="invoice-page-bulk-upload-file-input"
          @change="onUpload"
        >
        <div
          class="upload-block d-flex cursor-pointer align-center px-6 py-11  bg-other-3 border-gray-2 h-100"
          style="height: 100%"
          @dragover.prevent
          @drop.prevent="onDrop"
          @click="onClickDropArea"
        >
          <FileUploadIcon class="upload-icon" />
          <div class="upload-content">
            <p class="text-black-2 heading-desktop-5 pb-0 mb-2">
              {{ $t("v2.bulkUpload.bulkUploadText") }}
            </p>
            <p class="text-black-2 text-desktop-body-large pb-0 mb-1">
              {{ $t("v2.bulkUpload.weSupportPdf") }}
            </p>
          </div>
        </div>
      </div>
    </LayoutSideModal>

    <LayoutSideModal
      v-model="showInvoiceTypeModal"
      :width="540"
      title="Select Invoice Type"
      :on-close="closeInvoiceTypeModel"
    >
      <div
        class="pt-3"
        data-testid="add-container-upload-sub-options"
      >
        <p class="text-primary-1 heading-desktop-5 pb-0 mb-4">
          Select Invoice Type
        </p>
        <div>
          <div
            class="view-filter-list d-flex pa-5 mb-4 align-center bg-gray-5 border-gray-3 cursor-pointer rounded-lg"
            @click="handleInvoiceTypeSelected('IMPORT')"
          >
            <span class="text-desktop-body-large text-black-2">Import</span>
          </div>
          <div
            class="view-filter-list d-flex pa-5 mb-4 align-center bg-gray-5 border-gray-3 cursor-pointer rounded-lg"
            @click="handleInvoiceTypeSelected('EXPORT')"
          >
            <span class="text-desktop-body-large text-black-2">Export</span>
          </div>
          <div
            class="view-filter-list d-flex pa-5 mb-4 align-center bg-gray-5 border-gray-3 cursor-pointer rounded-lg"
            @click="handleInvoiceTypeSelected('FREIGHT')"
          >
            <span class="text-desktop-body-large text-black-2">Freight</span>
          </div>
        </div>
      </div>
    </LayoutSideModal>
  </div>
</template>

<style lang="scss">

</style>
