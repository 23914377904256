<script lang="ts" setup>
import { getContainerStatus, getStatusColor } from '~/utils/container.util';

const props = defineProps(
  {
    container: { required: true, type: Object },
  },
);
const { t } = useI18n();
const orgStore: any = useOrgStore();
const containerStatus: any = computed(() => getContainerStatus(props.container, t, orgStore.isPetaDisabled));
</script>

<template>
  <div
    v-if="containerStatus.status"
    :class="`bg-${getStatusColor(container)}-6  \
      text-${getStatusColor(container)}-${getStatusColor(container) === 'black' ? 4 : 1}`"
    class="container-status d-flex align-center  heading-desktop-7"
  >
    <span
      :class="`bg-${getStatusColor(container)}-${getStatusColor(container) === 'black' ? 4 : 1}
      `"
      class="dot mr-1 d-inline-block"
    />

    <span> {{ containerStatus.status }}</span>
  </div>
</template>

<style lang="scss">
.container-status {
  padding: 6px 12px;
  border-radius: 16px;
  white-space: nowrap;

  .dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;

  }
}
</style>
