<script setup lang="ts">
import AppBarWrapper from '~/components/layout/AppBars/AppBarWrapper.vue';
import { useFreightAuditStore } from '~/store/freight_audit';

const title: any = ref('Invoice No:');

const freightStore = useFreightAuditStore();
const invoiceNumber = computed(() => freightStore.invoiceData);

watch(invoiceNumber, (value: any) => {
  title.value = `Invoice No: ${value.invoice_identifier}`;
});
</script>

<template>
  <AppBarWrapper
    :title="title"
    back-route="/freight_audit/invoices"
    disable-search-input
    disable-org-selector
  >
    <template #afterTitle>
      <div class="freight-header">
        <v-chip
          v-if="freightStore.invoiceStatus"
          class="ml-4"
          color="blue"
          small
          text-color="white"
          style="font-weight: bold"
        >
          <span>
            • &nbsp;{{ freightStore.invoiceStatus.toLowerCase() }}
          </span>
        </v-chip>
      </div>
    </template>
  </AppBarWrapper>
</template>

<style  lang="scss">
.freight-header{
  .v-chip{
    height: 24px;
    .v-chip__content{
      text-transform: capitalize;
    }
    @extend .bg-primary-7;
    @extend .text-gray-6;
  }
}
</style>
