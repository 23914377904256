<script setup>
</script>

<template>
  <div style="width: 100vw;margin:0 24px">
    <!-- Header -->
    <div class="tw-space-y-4">
      <div class="tw-flex tw-justify-between tw-items-center tw-space-x-4">
        <div class="tw-skeleton tw-h-6 tw-w-2/5" />
        <div class="tw-flex tw-space-x-4">
          <div class="tw-skeleton tw-h-10 tw-w-20" />
          <div class="tw-skeleton tw-h-10 tw-w-20" />
          <div class="tw-skeleton tw-h-10 tw-w-20" />
        </div>
      </div>
    </div>
  </div>
</template>
