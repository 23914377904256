<script setup lang="ts">
import { VTreeview } from 'vuetify/labs/VTreeview';
import { changeSelection as changeSelection, createNewReport } from '~/utils/reports/reports.util';

const reportsStore: any = useReportsStore();
const userStore = useUserStore();

const categoryDropDownList = computed(() => reportsStore.categoryDropDownList);
const createColumnList = computed(() => reportsStore.createColumnList);
const version = computed(() => reportsStore.version);

onMounted(() => {
  reportsStore.fetchContainerColumns();
});

const changeHandler = (value: any) => {
  reportsStore.addSelectedValue(value);
};

const categoryColumn: any = ref();
const schedule: any = ref({
  name: '',
  schedule: '00 00 * * *',
});

const changeColumn = (columnType: any, selectedColumnName: string, columns: any) => {
  changeSelection(columnType, selectedColumnName, columns);
  reportsStore.updateColumns();
};

const deleteColumn = (value: any) => {
  value.isChecked = false;
  reportsStore.addSelectedValue(value);
};

const findColumns = (dropDownList: any) => {
  const searchColumnValue = dropDownList.find((item: any) => item.columnName == categoryColumn.value);
  reportsStore.searchFilterColumn(searchColumnValue);
};

const cloneReport = (selectedColumns: any, version: number, isActive: any) => {
  const newReport = createNewReport(selectedColumns, version, schedule, userStore);
  reportsStore.createReport(newReport);
  isActive.value = false;
};
</script>

<template>
  <v-dialog
    width="1500"
    persistent
  >
    <template #activator="{ props: activatorProps }">
      <v-btn
        v-bind="activatorProps"
        color="primary"
        variant="outlined"
      >
        Create new report
      </v-btn>
    </template>

    <template #default="{ isActive }">
      <v-card class="rounded-lg">
        <div class="d-flex align-center justify-space-between px-6 py-4">
          <div class="text-primary font-weight-bold">
            Create New Report Template
          </div>
          <div>
            <v-sheet width="350">
              <v-autocomplete
                v-model="categoryColumn"
                :items="categoryDropDownList"
                placeholder="Add column..."
                prepend-inner-icon="mdi-magnify"
                menu-icon=""
                variant="outlined"
                density="compact"
                item-title="columnName"
                item-value="columnName"
                hide-details="auto"
                @update:model-value="findColumns(categoryDropDownList)"
              >
                <template #item="{ props, item }">
                  <v-list-item
                    v-bind="props"
                    :title="item.raw.columnName"
                  />
                </template>
              </v-autocomplete>
            </v-sheet>
          </div>
          <v-btn
            variant="flat"
            icon="mdi mdi mdi-close"
            @click="isActive.value = false"
          />
        </div>
        <v-card-text class="py-0">
          <div class="text-caption">
            Report Name*
          </div>
          <div class="d-flex align-center">
            <v-responsive
              max-width="450"
            >
              <v-text-field
                v-model="schedule.name"
                density="compact"
                variant="outlined"
                placeholder="Field Name"
                clearable
              />
            </v-responsive>
            <div>
              <v-radio-group
                v-model="schedule.schedule"
                inline
              >
                <v-radio
                  label="Schedule On 8 am SGT"
                  value="00 00 * * *"
                />
                <v-radio
                  label="Turn off Schedule"
                  value=""
                />
              </v-radio-group>
            </div>
          </div>
        </v-card-text>
        <div class="d-flex justify-space-between">
          <div class="w-25 border-gray">
            <div class="pa-2 px-5 header">
              Select Selection
            </div>
            <div
              v-for="category in createColumnList.columnList"
              :key="category.id"
            >
              <div
                v-if="category.active"
                rounded="0"
                style="padding-right: 20px;"
                class="pa-2 px-4 ma-5 active-column text-primary cursor-pointer font-weight-bold"
                variant="tonal"
                color="primary"
              >
                {{ category.category }}
              </div>
              <div
                v-if="!category.active"
                class="pa-2 px-4 ma-5 cursor-pointer font-weight-bold"
                @click="changeColumn('category', category.category, createColumnList)"
              >
                {{ category.category }}
              </div>
            </div>
          </div>
          <div class="w-25 border-gray">
            <div class="pa-2 px-5 header">
              Select Sub Section
            </div>
            <div
              v-for="section in createColumnList.sectionList"
              :key="section"
            >
              <div
                v-if="section.active"
                rounded="0"
                style="padding-right: 20px;"
                class="pa-2 px-4 ma-5 active-column text-primary cursor-pointer font-weight-bold"
                variant="tonal"
                color="primary"
              >
                {{ section.section }}
              </div>
              <div
                v-if="!section.active"
                class="pa-2 px-4 ma-5 cursor-pointer font-weight-bold"
                @click="changeColumn('section', section.section, createColumnList)"
              >
                {{ section.section }}
              </div>
            </div>
          </div>
          <div
            class="w-25 border-gray"
          >
            <div class="pa-2 px-5 header">
              Select Columns
            </div>
            <div
              style="height: calc(53vh - 10px); overflow-y: scroll;overflow-x: hidden;"
            >
              <div
                v-for="value in createColumnList.valuesList"
                :key="value"
                class=""
              >
                <div
                  v-if="!value.isMenuValues"
                  class="font-weight-bold my-6 mx-4 ml-14"
                >
                  <v-checkbox-btn
                    v-model="value.isChecked"
                    :disabled="value.default"
                    color="primary"
                    :label="value.name"
                    :class="{ 'text-primary': value.isHighlight, 'active': value.isHighlight }"
                    @change="changeHandler(value)"
                  />
                </div>
                <div
                  v-if="value.isMenuValues"
                  class="font-weight-bold"
                >
                  <v-treeview
                    :items="value.values"
                    class="p-0 w-100"
                  >
                    <template
                      #prepend="{ item }"
                    >
                      <div
                        :class="{ active: item.isHighlight }"
                        class="w-100 mr-4 pr-16"
                      >
                        <v-checkbox-btn
                          v-model="item.isChecked"
                          :disabled="item.default"
                          :label="item.name"
                          color="primary"
                          class="w-100 pr-16"
                          :class="{ 'text-primary': item.isHighlight }"
                          @change="changeHandler(item)"
                          @click.stop
                        />
                      </div>
                    </template>
                  </v-treeview>
                </div>
              </div>
            </div>
          </div>
          <div
            class="w-25 border-gray"
          >
            <div class="pa-2 px-5 header">
              Selected Columns
            </div>
            <div
              style="height: calc(53vh - 10px); overflow: auto"
            >
              <div
                v-for="value in createColumnList.selectedColumns"
                :key="value"
              >
                <div
                  class="font-weight-bold my-6 px-5 d-flex align-center justify-space-between "
                >
                  {{ value.name }}
                  <v-btn
                    :disabled="value.default"
                    color="red"
                    icon="mdi mdi-trash-can-outline"
                    variant="text"
                    size="small"
                    @click="deleteColumn(value)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <v-card-actions class="pb-4 mr-3">
          <v-btn
            text="Close"
            color="primary"
            variant="outlined"
            @click="isActive.value = false"
          />
          <v-btn
            prepend-icon="mdi mdi-plus"
            text="Create Report"
            :disabled="!schedule.name"
            color="primary"
            variant="flat"
            @click="cloneReport(createColumnList.selectedColumns, version, isActive)"
          />
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<style lang="css" scoped>
.active-column {
  background: #EFFAFF;
  width: 60%;
  text-align: left;
}

.active {
  background: #EFFAFF;
  width: 100%;
  text-align: left;
}

.border-gray {
  border-right: 0.50px #DEE0E1 solid;
  border-top: 0.50px #DEE0E1 solid;
  border-bottom: 0.50px #DEE0E1 solid;
}

.header {
    background: #F3F6F9 !important;
    font-weight: 600 !important;
    color: #8D8CAF !important;
    position: relative;
    top: 0;
    z-index: 99;
}

:deep(.v-treeview-group.v-list-group .v-list-group__items .v-list-item:hover) {
  background:none !important;
}
</style>
