<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { LanguageIcon } from '~/utils/svg.util';

defineProps({
  className: { required: false, type: String, default: '' },
  large: { required: false, type: Boolean, default: false },
});
const lang: any = useI18n().locale || 'en';
const { setLocale } = useI18n();

const languageList = [
  {
    name: 'English',
    value: 'en',
  },
  {
    name: 'Español',
    value: 'es',
  },
  {
    name: 'Français',
    value: 'fr',
  },
  {
    name: '日本語',
    value: 'ja',
  },
  {
    name: 'Chinese (Simplified)',
    value: 'zh-TW',
  },
  {
    name: 'Chinese (Traditional)',
    value: 'zh-CN',
  },
];

const onLanguageChange = (e: any) => {
  window.localStorage.setItem('i18n_locale', e.value);
  setLocale(e.value);
};

const langName = () => {
  const langObj = languageList.find((item: any) => item.value.toLowerCase() === lang?.value?.toLowerCase());
  return langObj ? langObj.name : '';
};
</script>

<template>
  <v-menu
    class="dropdown-menu-v2 "
    offset-y
  >
    <template #activator="{ props }">
      <v-btn
        :class="`header-btn dropdown-menu-button mx-4`"
        variant="outlined"
        v-bind="props"
      >
        <template #prepend>
          <LanguageIcon class="mr-2 btn-icon heading-desktop-10" />
        </template>
        <template #append>
          <v-icon
            right
            size="22"
            style="margin-right:0 !important; "
          >
            mdi-chevron-down
          </v-icon>
        </template>
        {{ langName() }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in languageList"
        :key="index"
        @click="onLanguageChange(item)"
      >
        <v-list-item-title>{{ item.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style lang="scss">
.dropdown-menu-button {
  @extend .heading-desktop-5;
  @extend .text-black-2;
  @extend .border-black-2;

  background: #fff;
  text-transform: none;
  border-radius: 8px;
  border: 1px solid;
  letter-spacing: 0;

  &.large-btn {
    height: 44px;
  }

  .btn-icon {
    width: 20px;
    height: 22px;
  }

  &.btn-size--large {
    height: 40px !important;
  }

  &.v-btn--disabled {
    background-color: #ccc !important;
  }

  .v-icon {
    font-size: 18px !important;
    margin-right: 8px !important;
  }
}
</style>
