<script setup lang="ts">
import { useUserStore } from '~/store/user';
import TextInput from '~/components/common/TextInput.vue';

const open = ref(false);
const userStore: any = useUserStore();

const { t } = useI18n();

const orgList = computed(() => userStore.user?.org_scope_list?.filter(({ org_id }: any) => {
  return org_id !== userStore.user?.settings.default_org;
}) || []);

const selectedOrgName = computed(() => {
  return userStore.user?.org_scope_list?.find(
    (org: any) => org.org_id === userStore.user?.settings.default_org)?.org_name;
});

const list: any = ref([]);
const searchValue: any = ref('');

watchEffect(() => {
  list.value = orgList.value;
});

const closeSideModal = () => {
  open.value = false;
  searchValue.value = '';
  list.value = orgList.value;
};

const updateUserSettings = async (orgId: string) => {
  closeSideModal();
  await userStore.updateUserSettings({ default_org: orgId });
  location.href = '/';
};

const onSearch = (value: any) => {
  searchValue.value = value;
  list.value = value
    ? orgList.value.filter((org: any) => org.org_name.toLowerCase().includes(value.toLowerCase()))
    : orgList.value;
};
</script>

<template>
  <ClientOnly>
    <v-btn
      v-if="orgList?.length"
      class="text-none text-subtitle-1 "
      color="secondary"
      variant="flat"
      append-icon="mdi-chevron-down"
      @click="open = true"
    >
      {{ selectedOrgName }}
    </v-btn>
    <LayoutSideModal
      v-model="open"
      title="Organisation"
      :on-close="closeSideModal"
    >
      <TextInput
        class="mb-4"
        :value="searchValue"
        :placeholder="t('common.searchOrgs')"
        @on-change="onSearch"
      />
      <v-list lines="one">
        <v-list-item
          v-for="org in list"
          :key="org.org_id"
          class="bg-gray-5 border-gray-3 pa-5 mb-"
          style=" border: 0.5px solid #dad4d4; border-radius: 6px !important;  margin-bottom: 12px;"
          :title="org.org_name"
          @click="updateUserSettings(org.org_id)"
        />
      </v-list>
    </LayoutSideModal>
  </ClientOnly>
</template>
