<script lang="ts" setup>
import {
  CloseIcon,
  DownloadCircleIcon,
  DeleteIcon,
  // ScheduleAlertsIcon,
  DownloadIcon,
} from '~/utils/svg.util';
import { useContainersStore } from '~/store/containers';
import ConfirmationModal from '~/components/common/ConfirmationModal.vue';
import { prepareFiltersFromQueryParams } from '~/utils/container.util';

const containerStore = useContainersStore();

const { t } = useI18n();
const route = useRoute();
const snackbar = useSnackbar();

const selectedContainersList: any = computed(() => containerStore.selectedContainers);
const totalContainers: any = computed(() => containerStore.total);
const routeFilters: any = computed(() => prepareFiltersFromQueryParams(route.query));

const totalContainersSelected: any = ref(false);
const dialog: any = ref(false);
const cancelText: any = ref('');
const confirmText: any = ref('');
const dialogTitle: any = ref('');
const icon: any = ref('');
const description: any = ref('');

const deleteDialogTitle: any = t('common.deleteContainer');
const archiveDialogTitle: any = t('common.archiveContainer');
const downloadDialogTitle: any = t('common.download');

const onConfirm = () => {
  const containerCount = totalContainersSelected.value ? totalContainers.value : selectedContainersList.value.length;

  const ids = [...selectedContainersList.value];
  if (dialogTitle.value === deleteDialogTitle) {
    containerStore.deleteContainer(ids)
      .then(() => {
        snackbar.add({
          text: `${containerCount} Container${containerCount > 1 && 's'} Deleted Successfully`,
          type: 'success',
        });
      })
      .catch(() => {
        snackbar.add({
          text: t('common.somethingWentWrongTryAgainLatter'),
          type: 'error',
        });
      })
      .finally();
  }
  else if (dialogTitle.value === archiveDialogTitle) {
    containerStore.archiveContainer(ids)
      .then(() => {
        snackbar.add({
          text: `${containerCount} Container${containerCount > 1 && 's'} Archived Successfully`,
          type: 'success',
        });
      })
      .catch(() => {
        snackbar.add({
          text: t('common.somethingWentWrongTryAgainLatter'),
          type: 'error',
        });
      })
      .finally();
  }
  else if (dialogTitle.value === downloadDialogTitle) {
    if (containerCount > 300) {
      downloadOrEmailContainers('email');
    }
    else {
      downloadOrEmailContainers('download');
    }
  }

  dialog.value = false;
};

const closeDialog = () => {
  dialog.value = false;
};

const downloadOrEmailContainers = (mode: string) => {
  const params = { ...routeFilters.value };
  params.page = 1;
  params.per_page = totalContainers.value;
  params.mode = mode;
  snackbar.add({
    text: params.mode === 'email' ? t('containerPage.emailWillBeSentShortly') : t('containerPage.downloadWillBegin'),
    type: 'info',
  });
  containerStore.downloadContainers(params, totalContainersSelected.value).then((_response: any) => {
    clearSelection();
  }).catch(() => {
  });
};

const clearSelection = () => {
  totalContainersSelected.value = false;
  selectedContainersList.value = [];
  containerStore.setSelectedContainers([]);
};

const selectTotalContainer = () => {
  totalContainersSelected.value = true;
  const containersList: any = [];
  containerStore.containers.forEach((item: any) => {
    item.isSelected = true;
    containersList.push({ ...item });
  });

  containerStore.setSelectedContainers(containersList);
};

const getContainerCount = () => {
  return totalContainersSelected.value ? totalContainers : selectedContainersList.value.length;
};

// const scheduleAlert = () => {
//   cancelText.value = t('common.dontSchedule');
//   confirmText.value = t('common.scheduleAlerts');
//   dialogTitle.value = t('common.scheduleAlerts');
//   description.value = t('common.scheduleAlertMessage');
//   icon.value = ScheduleAlertsIcon;
//   dialog.value = true;
// };

const deleteDialogContainer = () => {
  cancelText.value = t('common.dontDelete');
  confirmText.value = deleteDialogTitle;
  dialogTitle.value = deleteDialogTitle;
  description.value = t('common.deleteContainerMessage');
  icon.value = DeleteIcon;
  dialog.value = true;
};

const downloadContainer = () => {
  const containerCount = totalContainersSelected.value ? totalContainers.value : selectedContainersList.value.length;
  cancelText.value = t('common.dontDownload');
  confirmText.value = containerCount > 300 ? t('common.shareViaEmail') : downloadDialogTitle;
  dialogTitle.value = downloadDialogTitle;
  description.value = containerCount > 300
    ? t('common.download300ContainerMessage')
    : t('common.downloadContainerMessage');
  icon.value = DownloadIcon;
  dialog.value = true;
};
</script>

<template>
  <div class="selection-bar container-header d-flex align-center bg-gray-6 py-4 px-8">
    <v-row no-gutters>
      <v-col
        md="6"
        lg="6"
        xl="6"
        cols="12"
        class="d-flex align-center"
      >
        <div class="d-flex align-center">
          <span
            class="cursor-pointer d-flex"
            @click="clearSelection"
          >
            <CloseIcon
              class="mr-5"
              style="width: 14px"
            />

            <span class="heading-desktop-5 text-black-1">
              {{ getContainerCount() }}
              {{
                getContainerCount() === 1 ? $t("common.container")
                : $t("common.containers")
              }} {{ $t("common.selected") }}
            </span>
          </span>
          <span
            v-if="!totalContainersSelected"
            class="select-total-containers px-4 py-2 highlight-btn"
            @click="selectTotalContainer"
          >
            {{ $t("common.selectAllContainers", { count: totalContainers }) }}
          </span>
        </div>
      </v-col>
      <v-col
        md="6"
        lg="6"
        xl="6"
        cols="12"
        class="d-flex selection-bar__actions"
      >
        <div class="d-flex selection-bar__actions">
          <v-btn
            icon
            color="#EDFAFF"
            @click="deleteDialogContainer"
          >
            <v-tooltip bottom>
              <template #activator="{ props }">
                <DeleteIcon
                  class="cursor-pointer"
                  v-bind="props"
                />
              </template>
              <span>
                {{ $t("common.delete") }}
              </span>
            </v-tooltip>
          </v-btn>

          <v-btn
            icon
            color="#EDFAFF"
            @click="downloadContainer"
          >
            <v-tooltip bottom>
              <template #activator="{ props }">
                <DownloadCircleIcon v-bind="props" />
              </template>
              <span>
                {{ $t("common.download") }}
              </span>
            </v-tooltip>
          </v-btn>

          <!--          <v-btn -->
          <!--            icon -->
          <!--            color="#EDFAFF" -->
          <!--            @click="scheduleAlert" -->
          <!--          > -->
          <!--            <v-tooltip bottom> -->
          <!--              <template #activator="{ props }"> -->
          <!--                <ScheduleAlertsIcon v-bind="props" /> -->
          <!--              </template> -->
          <!--              <span> -->
          <!--                Schedule Alert -->
          <!--              </span> -->
          <!--            </v-tooltip> -->
          <!--          </v-btn> -->
        </div>

        <ConfirmationModal
          data-testid="container-confirmation-dialog"
          :show="dialog"
          :description="description"
          :icon="icon"
          :cancel-text="cancelText"
          :confirm-text="confirmText"
          :dialog-title="dialogTitle"
          @on-cancel="closeDialog"
          @on-confirm="onConfirm"
        />
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss">
.selection-bar {

  @extend .border-gray-4;
  //border-bottom: 1px solid;
  margin: 0 -12px 0 -12px !important;
  min-height: 80px;
  max-width: none;

  &__actions {
    justify-content: end;
    column-gap: 48px;

    .v-btn {
      width: auto;
    }
  }

  .select-total-containers {
    @extend .heading-desktop-18;
    @extend .text-black-2;
    @extend .border-black-2;
    border: 1px solid;
    cursor: pointer;
    margin-left: 101px;
    border-radius: 6px;

    &:hover {
      @extend .bg-primary-6;
      @extend .text-primary-1;
      @extend .border-primary-1;
    }
  }

  .highlight-btn {
    animation: shake 0.6s;
    animation-iteration-count: 1;
  }

  @media (max-width: 999px) {
    width: 100%;
  }

}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
</style>
